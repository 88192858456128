<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px"  label-position="top" style="margin-bottom: -5px;">
      <!-- <el-form-item :label="td('订单号码')" prop="orderId">
        <el-input
          v-model="queryParams.orderId"
          :placeholder="td('请输入订单号码')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <el-form-item :label="td('创建时间')">
        <el-date-picker
                v-model="daterangeCreateTime"
                size="small"
                style="width: 360px"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="-"
                :start-placeholder="td('开始日期')"
                :end-placeholder="td('结束日期')"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="td('入库时间')">
        <el-date-picker
          v-model="daterangeLastInTime"
          size="small"
          style="width: 360px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="-"
          :start-placeholder="td('开始日期')"
          :end-placeholder="td('结束日期')"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="td('签收状态')" prop="signState">
        <el-select v-model="queryParams.signState" :placeholder="td('请选择签收状态')" clearable size="small">
          <el-option
            v-for="dict in tf('SIGN_TYPE')"
            :key="dict.detailId"
            :label="dict.langTranslate"
            :value="dict.detailId" />
        </el-select>
      </el-form-item>
      <el-form-item :label="td('批次号')" prop="orderPackId">
        <el-input
          v-model="queryParams.orderPackId"
          :placeholder="td('请输入批次号')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <!-- <el-form-item :label="td('派件员')" prop="dispatch">
        <el-input
          v-model="queryParams.dispatch"
          :placeholder="td('请输入派件员')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
    </el-form>

    <el-row :gutter="10" style="margin-top: 20px;">
      <!-- <el-col :span="1.5">
        <el-button
          type="info"

          icon="el-icon-download"
          size="mini"
          @click="handleExport"
        >{{td("导出")}} </el-button>
      </el-col> -->

      <el-col :span="1.5">
      <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{td("搜索")}} </el-button>
      <el-button type="primary" icon="el-icon-refresh" size="mini" @click="resetQuery">{{td("重置")}} </el-button>
      </el-col>

      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="OrderSignList" @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" width="55" align="center" /> -->cuscode
      <!-- <el-table-column :label="td('客户代号')" align="center" prop="customerId" /> -->
      <el-table-column :label="td('客户代号')" align="center" prop="cuscode" width="120"/>
      <el-table-column :label="td('批次号')" align="center" prop="orderPackId" />
      <el-table-column :label="td('创建时间')" align="center" prop="createTime" width="160">
        <template slot-scope="scope">
          <!-- <span>{{ parseTime(scope.row.createTime, '{y}-{m}-{d}') }}</span> -->
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="td('入库时间')" align="center" prop="receTime" width="160">
        <template slot-scope="scope">
          <!-- <span>{{ parseTime(scope.row.createTime, '{y}-{m}-{d}') }}</span> -->
          <span>{{ parseTime(scope.row.receTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="td('快递号')" align="center" prop="expressId" width="150"/>
      <!-- <el-table-column :label="td('类目')" align="center" prop="goodsType" /> -->
       <el-table-column :label="td('类目')" align="center" prop="goodsType" >
        <template slot-scope="scope">
          {{ tfp("GOODS_TYPE", scope.row.goodsType) }}
        </template>
       </el-table-column>
      <!-- <el-table-column :label="td('运输方式')" align="center" prop="deliveryInter" /> -->
      <!-- <el-table-column :label="td('运输方式')" align="center" prop="channel" /> -->
      <el-table-column :label="td('运输方式')" align="center" prop="channel" >
        <template slot-scope="scope">
          {{ tfp("TRANSPORT_CHANNEL", scope.row.channel) }}
        </template>
       </el-table-column>
      <el-table-column :label="td('商品')" align="center" prop="goodsName" width="150">
        <template slot-scope="scope">{{goodsTypeMap.get(scope.row.goodsName)}}</template>
      </el-table-column>
      <el-table-column :label="td('订单编号')" align="center" prop="orderId" width="100"/><!--未有,用orderId-->
      <el-table-column :label="td('重量')" align="center" prop="weightS" />
      <el-table-column :label="td('数量')" align="center" prop="qtyInput" />
<!--      <el-table-column :label="td('泰国货代')" align="center" prop="deliveryCom" />-->
      <el-table-column :label="td('签收状态')" align="center" prop="signState">
        <template slot-scope="scope">
          {{ tfp("SIGN_TYPE", scope.row.signState) }}
        </template>
      </el-table-column>
      <el-table-column :label="td('收件人')" align="center" prop="addressee" width="120"/>
      <el-table-column :label="td('操作')" fixed="right" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-folder-checked" @click="orderPrint(scope.row)">
            {{ td("打印") }}
          </el-button>
<!--          <el-button size="mini" type="text" icon="el-icon-folder-checked" @click="preview(scope.row)">-->
<!--            {{ td("预览") }}-->
<!--          </el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改订单签收，同单号支持多次签收对话框 -->
   <el-dialog
:close-on-click-modal="false" :title="title" :visible.sync="open" width="500px"  append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-position="top" label-width="80px">
        <el-form-item :label="td('订单号码')" prop="orderId">
          <el-input v-model="form.orderId" :placeholder="td('请输入订单号码')" />
        </el-form-item>
        <el-form-item :label="td('签收人')" prop="signMan">
          <el-input v-model="form.signMan" :placeholder="td('请输入签收人，手工录入')" />
        </el-form-item>
        <el-form-item :label="td('签收时间')" prop="signTime">
          <el-date-picker clearable size="small"
            v-model="form.signTime"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="td('选择签收时间')" style="width: 460px;">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="td('签收状态')" prop="signState">
          <el-select v-model="form.signState" :placeholder="td('请选择签收状态')" clearable size="small" style="width: 460px;">
            <el-option
              v-for="dict in tf('SIGN_TYPE')"
              :key="dict.detailId"
              :label="dict.langTranslate"
              :value="dict.detailId" />
          </el-select>
        </el-form-item>
        <el-form-item :label="td('异常原因')" prop="causes">
          <el-input v-model="form.causes" :placeholder="td('请输入异常原因，手工录入')" />
        </el-form-item>
        <el-form-item :label="td('派件员')" prop="dispatch">
          <el-input v-model="form.dispatch" :placeholder="td('请输入派件员，取员工列sys_user')" />
        </el-form-item>
        <el-form-item :label="td('签收网点')" prop="signSite">
          <el-input v-model="form.signSite" :placeholder="td('请输入签收网点:取TMS_site的sitecode')" />
        </el-form-item>
        <el-form-item :label="td('录入员工')" prop="createUser">
          <el-input v-model="form.createUser" :placeholder="td('请输入录入员工')" />
        </el-form-item>
        <el-form-item :label="td('录入网点')" prop="createSite">
          <el-input v-model="form.createSite" :placeholder="td('请输入录入网点')" />
        </el-form-item>
        <el-form-item :label="td('备注')" prop="remark">
          <el-input v-model="form.remark" :placeholder="td('请输入备注')" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">{{td("确定")}}</el-button>
        <el-button @click="cancel">{{td("取消")}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getGoodTypeAndName } from '@/api/tms/actionCenter'
import { selectErpToBeSignedOrderList, erpToBeSignedOrderPrint} from "@/api/tms/OrderSign";
import { listCustomer }from "@/api/system/customer"
import { getLodop } from '@/api/tms/LodopFuncs'
import Cookies from "js-cookie"
export default {
  name: "orderSign",
  data() {
    return {
      // 货品类型和名称集合
      goodsTypeList: [],
      goodsTypeMap: new Map(),
      // 国家id
      langId: null,
      // 时间范围--start
      // daterangeLastInTime: [new Date(2022, 2, 10, 0, 0), new Date(2022, 2, 10, 23, 59)],
      // daterangeLastInTime: [],
      daterangeLastInTime: null,
      daterangeCreateTime: [this.parseTime(new Date(new Date().toLocaleDateString()).getTime()),this.parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1))],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      clearable: [],
      cusData: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 订单签收，同单号支持多次签收表格数据
      OrderSignList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {//这里需要处理-4.29
        searchStartTime: null,
        searchEndTime: null,
        customerId: null,
        orderPackId: null,
        pageNum: 1,
        pageSize: 10,
        orderId: null,
        signMan: null,
        signTime: null,
        signState: "10290",
        causes: null,
        dispatch: null,
        signSite: null,
        createUser: null,
        createSite: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        signTime: [
          { required: true, message: this.td("'签收时间'不能为空"), trigger: "blur" }
        ],
      }
    };
  },
  created() {
    // 加载货品类型和名称
    this.loadGoodTypeAndName()
    this.getList();
  },
  methods: {
    /** 获取货品类型和名称 */
    loadGoodTypeAndName() {
      getGoodTypeAndName().then(res => {
        this.goodsTypeList = res.data
        for (let goodsType of this.goodsTypeList) {
          goodsType.label = goodsType.goodsTypeC + '/' + goodsType.goodsNameC
          this.goodsTypeMap.set(goodsType.goodsId, goodsType.goodsNameC)
        }
      })
    },
    /** 查询订单签收，同单号支持多次签收列表 */
    getList() {
      this.loading = true;
        //时间搜索--start
        if (null != this.daterangeLastInTime && '' != this.daterangeLastInTime) {
          this.queryParams.searchStartTime = this.daterangeLastInTime[0]
          this.queryParams.searchEndTime = this.daterangeLastInTime[1]
        }else{
            this.queryParams.searchStartTime = null
            this.queryParams.searchEndTime = null
          }
        // 创建时间
      if ( this.daterangeCreateTime &&   this.daterangeCreateTime != '') {
        this.queryParams.createStartTime = this.daterangeCreateTime[0]
        this.queryParams.createEndTime = this.daterangeCreateTime[1]
      }else{
        this.queryParams.createStartTime = null
        this.queryParams.createEndTime = null
      }
      selectErpToBeSignedOrderList(this.queryParams).then(response => {
        this.OrderSignList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        orderSignId: null,
        orderId: null,
        signMan: null,
        signTime: null,
        signState: null,
        causes: null,
        dispatch: null,
        signSite: null,
        createUser: null,
        createTime: null,
        createSite: null,
        remark: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeLastInTime = []//创建时间搜索
      this.queryParams.searchStartTime = null,
      this.queryParams.searchEndTime = null,
      this.daterangeLastInTime = null,
      this.daterangeCreateTime = [this.parseTime(new Date(new Date().toLocaleDateString()).getTime()),this.parseTime(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1))],

      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.orderSignId)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = this.td("添加订单签收，同单号支持多次签收");
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const orderSignId = row.orderSignId || this.ids
      getOrderSign(orderSignId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = this.td("修改订单签收，同单号支持多次签收");
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.orderSignId != null) {
            updateOrderSign(this.form).then(response => {
              // this.$modal.msgSuccess(this.td("修改成功"));
              this.msgSuccess(this.td("修改成功"));
              this.open = false;
              this.getList();
            });
          } else {
            addOrderSign(this.form).then(response => {
              // this.$modal.msgSuccess(this.td("新增成功"));//111
              this.msgSuccess(this.td("新增成功"));
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const orderSignIds = row.orderSignId || this.ids;
      // this.$modal.confirm('是否确认删除订单签收，同单号支持多次签收编号为"' + orderSignIds + '"的数据项？').then(function() {//111
        this.$confirm(this.td("是否确认删除")+this.td("订单签收，同单号支持多次签收")+this.td("编号为") + orderSignIds + this.td("数据项？")).then(function() {
        return delOrderSign(orderSignIds);
      }).then(() => {
        this.getList();
        // this.$modal.msgSuccess(this.td("删除成功"));
        this.msgSuccess(this.td("删除成功"));
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('tms/Order/orderExport', {
        ...this.queryParams
      }, `集运待签收.xlsx`)
    },
    /** 打印按钮 */
    orderPrint(row) {
		// console.log(row,"@@@@@@@");
      // 获取国家id
      this.langId = Cookies.get("langId")
      erpToBeSignedOrderPrint(row.expressId, this.langId).then(res => {
        if (res.code === 200) {
          // 执行打印功能
          this.print(res.data);


		  console.log(res.data,"@@@@@@@@");
        }
      })
    },
    /** 打印 */

	print(data) {
      let LODOP = getLodop()
      LODOP.PRINT_INIT("打印标签"); //调用getLodop获取LODOP对象
      LODOP.SET_PRINT_PAGESIZE(1, '105mm', '148mm', "");
      LODOP.SET_PRINT_STYLE("FontSize", 8);
      for (const it of data) {
        LODOP.NewPage()
		// LODOP.ADD_PRINT_BARCODE("1mm", "40mm", "46mm", "15mm", 'EAN128A', it.cuscode)
        LODOP.ADD_PRINT_BARCODE("17mm", "32mm", "55mm", "15mm", "EAN128A", it.expressId)
        let size = ''
        if (it.vlength && it.vwidth && it.vheight) {
          size = it.vlength + '×' + it.vwidth + '×' + it.vheight
        }
		it.boxNo = it.boxNo == null ? '' : it.boxNo
		it.countryId = it.countryId == null ? '' : it.countryId
		it.channel = it.channel == null ? '' : it.channel
		it.pcs = it.pcs == null ? '' : it.pcs
		it.weight = it.weight == null ? '' : it.weight
        let strHtml = "<table style=' border-collapse: collapse; ' border='1' Width='396' height='559' Cellspacing='0'>"+
		"<tr>"+"<td Width='100' align='center'>"+this.td("快递单号")+"</td>"+"<td Width='220' align='center'>" + it.expressId + "</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' align='center'>"+this.td("快递单号")+"</td>"+"<td Width='220'>"+"</td>"+"<td align='center'>"+it.boxNo+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' align='center'>"+this.td("箱号")+"</td>"+"<td Width='220' align='center'>"+ it.boxNo +"</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' align='center'>"+this.td("客户代号")+"</td>"+"<td Width='220' align='center'>"+it.cuscode+"</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' align='center'>"+this.td("目的国")+"</td>"+"<td Width='220' align='center'>" + it.countryId +"</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' height='63' align='center'>"+this.td("运输方式")+"<br/>"+"Mode of Shipment"+"</td>"+
		"<td Width='186' align='center'>" + it.channel +"</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' height='63' align='center'>"+this.td("产品数量")+"<br/>"+"Quantity(Pcs)"+"</td>"+"<td Width='220' align='center'>" + it.pcs +"</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' height='63' align='center'>"+this.td("外箱尺寸")+"<br/>"+"Carton Size(L*W*H cm)"+"</td>"+"<td Width='220' align='center'>" + size +"</td>"+"<td>"+"</td>"+"</tr>"+
		"<tr>"+"<td Width='100' height='63' align='center'>"+this.td("重量")+"<br/>"+"Weight(Kg)"+"</td>"+"<td Width='220' align='center'>" + it.weight+"</td>"+"<td>"+"</td>"+"</tr>"+
		"</table>";
        LODOP.ADD_PRINT_HTM("0",'0',"100%","100%",strHtml);
      }
      LODOP.PREVIEW() // 弹框
    },
  }
};
</script>
<style>
	/* table {
	  border-collapse: collapse;
	  width: 600px;
	}

	table, th, td {
	  border: 1px solid black;
	} */
</style>
